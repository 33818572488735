import React from "react";
import styled from "styled-components";

const Education = () => {
	return (
		<Section id="education">
			<h2>Education</h2>
			<Card>
				<Logo src="/odu.png" alt="Old Dominion University" />
				<Details>
					<h3>M.S. Cybersecurity</h3>
					<p>Norfolk, USA | 2024-Present</p>
				</Details>
			</Card>
			<Card>
				<Logo src="/odu.png" alt="Old Dominion University" />
				<Details>
					<h3>B.S. Cybersecurity, Minor in Data Science</h3>
					<p>Norfolk, USA | 2022-Present</p>
                    <p>2024 - Present: Interfraternity Council Chair for Sigma Phi Epsilon</p>
                    <p>2024: Joined Sigma Phi Epsilon</p>
                    <p>2023-Present: Treasurer for the Men's Rugby Club</p>
                    <p>2023-Present: President for the Men's Rugby Club</p>
                    <p>2023-2024: Sport Clubs Executive Council Member</p>
                    <p>2022-2023: Recruitment Chair for the Men's Rugby Club</p>
				</Details>
			</Card>
			<Card>
				<Logo src="/dtu.png" alt="Technical University of Denmark" />
				<Details>
					<h3>General Engineering</h3>
					<p>Lygnby, DENMARK | 2018-2022</p>
					<p>2018-2022: Council Member for the GE Bachelor</p>
                    <p>2018-2020: Social Council Member</p>
                    <p>2018-2019: Common Council Member</p>
                    <p>2019 - 2020: PF Wall Award Winner</p>
                    <p>2019 - 2020: Administrative Council</p>
				</Details>
			</Card>
		</Section>
	);
};

export default Education;

const Section = styled.section`
	padding: 70px 20px;
	background-color: var(--navy);
`;

const Card = styled.div`
	display: flex;
	background-color: var(--lightest-navy);
	padding: 20px;
	margin-bottom: 20px;
	border-radius: 8px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;

const Logo = styled.img`
	width: 80px;
	height: 80px;
	margin-right: 20px;
`;

const Details = styled.div`
	h3 {
		margin-bottom: 5px;
		color: var(--green);
	}

	p {
		margin: 5px 0;
		color: var(--lightest-slate);
	}
`;
