import React from "react";
import styled from "styled-components";

const Projects = () => {
	return (
		<Section id="projects">
			<h2>Projects</h2>
			<Card>
				<Details>
					<h3>HPC Wiki RAG Agent</h3>
					<p>LLM | 2024</p>
					<p>Currently working on a RAG agent for the High Performance Computing wiki of Old Dominion University</p>
				</Details>
			</Card>
			<Card>
				<Details>
					<h3>Horsey</h3>
					<p>Discord bot | 2020 - 2021</p>
					<p>Sole developer</p>
					<p>Over 150,000 users in more than 170 servers</p>
				</Details>
			</Card>
			<Card>
				<Details>
					<h3>Plasma</h3>
					<p>Discord bot | 2019 - 2021</p>
					<p>Developer and Support Team member</p>
					<p>Had over 6 million users in more than 11,000 discord servers</p>
				</Details>
			</Card>
			{/* Add more experience entries as needed */}
		</Section>
	);
};

export default Projects;

const Section = styled.section`
	padding: 70px 20px;
	background-color: var(--navy);
`;

const Card = styled.div`
	display: flex;
	background-color: var(--lightest-navy);
	padding: 20px;
	margin-bottom: 20px;
	border-radius: 8px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;

const Details = styled.div`
	h3 {
		margin-bottom: 5px;
		color: var(--green);
	}

	p {
		margin: 5px 0;
		color: var(--lightest-slate);
	}
`;
